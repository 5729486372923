import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();

  useEffect(() => {
    const simucoachBaseUrl = process.env.REACT_APP_SIMUCOACH_URL;
    const redirectUrl = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL!);

    // Extract redirectCount from query parameters or session storage
    const getRedirectCount = (): number => {
      const urlParams = new URLSearchParams(window.location.search);
      const queryParamCount = parseInt(
        urlParams.get("redirectCount") || "0",
        10
      );
      const sessionCount = parseInt(
        sessionStorage.getItem("redirectCount") || "0",
        10
      );
      return Math.max(queryParamCount, sessionCount); // Use the highest value for reliability
    };

    const setRedirectCount = (count: number) => {
      sessionStorage.setItem("redirectCount", count.toString()); // Update session storage
      console.log(`Set redirectCount to ${count} in session storage.`);
    };

    const redirectToLogin = (redirectCount: number) => {
      if (redirectCount < 2) {
        console.log(
          `Redirect attempt ${redirectCount + 1}. Redirecting to login...`
        );
        const newRedirectCount = redirectCount + 1;
        setRedirectCount(newRedirectCount); // Save in session storage
        //window.location.href = `${simucoachBaseUrl}/oauth2/authorization/azure?redirectCount=${newRedirectCount}&state=${redirectUrl}`;
        const loginUrl = `${simucoachBaseUrl}/oauth2/authorization/azure?redirectCount=${newRedirectCount}&state=${redirectUrl}`;

        if (window.self !== window.top) {
          // Inside iframe, use popup
          const popup = window.open(
              loginUrl,
              "LoginPopup",
              "width=600,height=700"
          );
          const checkPopupClosed = setInterval(() => {
            if (popup && popup.closed) {
              clearInterval(checkPopupClosed);
              console.log("Popup closed. Reloading parent window...");
              window.location.reload();
            }
          }, 500);
        }else {
          // Outside iframe, redirect directly
          window.location.href = loginUrl;
        }
      } else {
        console.warn(
          "Maximum redirect attempts reached. Stopping further redirects."
        );
      }
    };

    const fetchUserData = async () => {
      try {
        const redirectCount = getRedirectCount(); // Get the current redirect count

        const response = await fetch(`${simucoachBaseUrl}/user/me`, {
          method: "GET",
          credentials: "include",
          redirect: "manual",
        });

        console.log("Response Status:", response.status);

        if (response.status === 403 || response.status === 302) {
          redirectToLogin(redirectCount); // Handle redirection
        } else if (response.ok) {
          const data = await response.json();
          console.log("User data:", data);
          setUser(data);
          localStorage.setItem("user", JSON.stringify(data));
          navigate("/pages"); // Navigate to main app page
        } else {
          console.error("Unexpected response. Redirecting to login...");
          redirectToLogin(redirectCount); // Handle redirection
        }
      } catch (error) {
        console.error("Fetch error:", error);
        const redirectCount = getRedirectCount(); // Get current redirect count
        redirectToLogin(redirectCount); // Handle redirection
      }
    };

    fetchUserData();
  }, [setUser, navigate]);

  return <div className="login-page"></div>;
};

export default Login;
