import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardHeader, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { TrainingScenario } from '../../types/TrainingScenario';
import "./AssignmentDetail.css";

interface AssignmentDetailProps {
  onStartConversation: () => void;
  assignment: TrainingScenario;
}

const AssignmentDetail: React.FC<AssignmentDetailProps> = ({ onStartConversation, assignment }) => {
  console.info(assignment);
  return (
    <Box className="module-page-container">

      <Typography className="module-title" gutterBottom fontSize="40px" fontWeight="bold">
        {assignment.training.training_title}
      </Typography>
      
      <Box className="module-date-and-toggle">
        <Typography className="module-deadline">
          <strong> Due Date: </strong> {assignment.deadline}
        </Typography>
        <FormGroup>
          <FormControlLabel disabled control={<Switch defaultChecked />} label="Training Mode" className="training-toggle" />
        </FormGroup>
      </Box>

      <Box className="assignment-skill-desc-instr-container" display="flex">

        <Box className="description-and-skill-box" display="flex">
          <Card className="module-scrollable-card">
            <CardHeader 
              title="Description" 
              className="module-styled-header" 
              titleTypographyProps={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'left',
              }} 
            />
            <CardContent className="module-styled-content desc">
              <Typography className="training-module-card-text">{assignment.training.training_description}</Typography>
            </CardContent>
          </Card>

          <Card className="module-scrollable-card">
            <CardHeader 
              title="Skill Groups" 
              className="module-styled-header" 
              titleTypographyProps={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'left',
              }} 
            />
            <CardContent className="module-styled-content">
              <Typography className="training-module-card-text">
                {assignment.training.targeted_skills?.map((skill: string, index: number) => (
                  <li key={index}>{skill}</li>
                ))}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box className="instruction-box">
          <Card className="module-scrollable-card instruction-card">
            <CardHeader 
              title="Instructions" 
              className="module-styled-header" 
              titleTypographyProps={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'left',
              }} 
            />
            <CardContent className="module-styled-content instruction-card">
              <Typography className="training-module-card-text">{assignment.training.instructions}</Typography>
            </CardContent>
          </Card>
        </Box>

      </Box>

      <Box className="module-footer">
        <Button variant="contained" color="primary" onClick={onStartConversation} className="module-start-conversation">
          Begin Simulation
        </Button>
      </Box>

    </Box>
  );
};

export default AssignmentDetail;
