import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Route, Routes, Navigate, useNavigate, Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Banner from '../components/Banner/Banner';
import Dashboard from './Pages/Dashboard/Dashboard';
import Assignments from './Pages/Assignments/Assignments';
import AssignmentPage from './Pages/Assignments/AssignmentPage/AssignmentPage';
import ManageAssignments from './Pages/ManageAssignments/ManageAssignments';
import Library from './Pages/Library/Library';
import Progress from './Pages/Progress/Progress';
import Settings from './Pages/Settings/Settings';
import Overview from './Pages/Overview/Overview';
import Reports from './Pages/Reports/Reports';
import Team from './Pages/Team/Team';
import './Pages.css';
import { AgentSidebarItems, ManagerSidebarItems } from '../constant/SidebarItems';
import { useUser } from '../context/UserContext';
import { ProgressPage } from './Pages/Progress/ProgressPage/ProgressPage';
import { TrialProvider } from '../context/TrialContext';
import { Icon, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TrainingScenarioProvider } from '../context/TrainingScenarioContext';

if (window.opener) {
    console.log("in popup window, auto close");
    window.close();
} else {
    console.log("Not in popup, continue.");
}
function AssignmentsWrapper() {
  return (
    <TrainingScenarioProvider>
      <Outlet />
    </TrainingScenarioProvider>
  );
}

function ProgressWrapper() {
  return (
    <TrialProvider>
      <Outlet />
    </TrialProvider>
  );
}

const Pages: React.FC = () => {
  const { user } = useUser();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsSidebarOpen(false);
  };

  const sidebarItems = user?.roles[0] === 'ROLE_AGENT' ? AgentSidebarItems : ManagerSidebarItems;

  return (
    <div className="pages-layout">
      <Banner />
      <div className="content-wrapper"/>
      <IconButton
        onClick={toggleSidebar}
        className="sidebar-toggle-button"
        color="inherit"
      >
        {isSidebarOpen ? <Icon/> : <ArrowForwardIosIcon />}
      </IconButton>

      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        onNavigate={handleNavigation}
        items={sidebarItems}
      />

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      <div className={`page-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <Routes>
          <Route
            path="/"
            element={
              user?.roles[0] === 'ROLE_AGENT' ? (
                <Navigate to="/pages/dashboard" replace />
              ) : user?.roles[0] === 'ROLE_MANAGER' ? (
                <Navigate to="/pages/overview" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="dashboard" element={<Dashboard />} />
          <Route element={<ProgressWrapper />}>
            <Route element={<AssignmentsWrapper />}>
              <Route path="assignments" element={<Assignments />} />
              <Route path="assignments/:id" element={<AssignmentPage />} />
            </Route>
            <Route path="manage-assignments" element={<ManageAssignments />} />
            <Route path="library" element={<Library />} />
            <Route path="progress" element={<Progress />} />
            <Route path="progress/:id" element={<ProgressPage />} />
          </Route>
          <Route path="overview" element={<Overview />} />
          <Route path="reports" element={<Reports />} />
          <Route path="team" element={<Team />} />
        </Routes>
      </div>
    </div>
  );
};

export default Pages;

