import React from "react";
import { Feedback } from "../../types/Feedback";
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Pill from "../Pill/Pill";

interface ProgressAccordionProps {
    feedback: Feedback | null;
}

const ProgressAccordion: React.FC<ProgressAccordionProps> = ({ feedback }) => {
    return <Box sx={{ width: "100%" }}>
        {
            Object.entries(feedback!.detailed_comment).map(([category, metrics]) => {
                category = category.trim();
                const categoryScores = Object.fromEntries(
                    Object.entries(feedback!.detail_score).map(([category, score]) => [category, Math.round(Number(score))])
                )
                return <Accordion key={category} sx={{
                    margin: 0,
                    boxShadow: "none",
                    border: "1px solid #ddd",
                    "&.Mui-expanded": {
                        margin: 0, // Removes extra margin when expanded
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${category}-content`}
                        id={`panel${category}-header`}
                        sx={{
                            flexDirection: "row-reverse",
                            justifyContent: "space-between", // Moves icon to the left
                            width: "100%"
                        }}
                    >
                        <Typography variant="h6" style={{ marginLeft: "10px" }}>{category}</Typography>
                        <Pill size="h6" type={categoryScores[category] >= 3 ? "pill-passed" : "pill-failed"} customClass="pill-justified">
                            {categoryScores[category] + " / 5"}
                        </Pill>


                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            backgroundColor: "#FAFAFA",
                            color: "#333",
                            display: "flex",
                            flexDirection: "column",
                            gap: "30px",
                            paddingTop: "30px"
                        }}
                    >
                        {Object.entries(metrics).map(([metric, { score, comment, description }]) => (
                            <Box style={{ display: "flex", gap: "40px" }} key={metric}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: Number(score) >= 3 ? "#155724" : "#721c24",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    {score} / 5
                                </Typography>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }} >
                                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                            {metric}
                                        </Typography>
                                        <Tooltip title={description} placement="top">
                                            <InfoOutlinedIcon fontSize="small" />
                                        </Tooltip>
                                    </Box>
                                    <Typography variant="body2">{comment}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            })
        }
    </Box>
};

export default ProgressAccordion;