import React from "react";
import './Pill.css';

interface PillProps {
  type: "pill-passed" | "pill-failed";
  size: "body1" | "large" | "h6"
  customClass?: string;
  children: React.ReactNode;
}

const Pill: React.FC<PillProps> = ({ type, size, customClass, children }) => {
  const pillClass = `pill ${customClass} ${size} ${type}`;

  return <span className={pillClass}>{children}</span>;
};

export default Pill;
