// ProgressPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTrials } from '../../../../context/TrialContext';
import { getFeedback } from '../../../../services/feedbackService';
import { Feedback } from '../../../../types/Feedback';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen'; // Import the LoadingScreen component
import { Box, LinearProgress, Typography } from '@mui/material';

import './ProgressPage.css';
import ProgressAccordion from '../../../../components/ProgressAccordion/ProgressAccordion';
import { getPlayback } from '../../../../services/audioService';

export const ProgressPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { getTrial } = useTrials();
  const [audioUrl, setAudioUrl] = useState<string | undefined>();
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [localLoading, setLocalLoading] = useState(true); // Local loading state

  const trial = getTrial(id!);
  useEffect(() => {
    const init = async () => {
      setLocalLoading(true);
      try {
        if (trial) {
          const fetchedFeedback = await getFeedback(trial.feedback_id);
          setFeedback(fetchedFeedback);

          const audioBlob = await getPlayback(`${trial.conversationHistory}/conversation.wav`);
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
        }
      } finally {
        setLocalLoading(false);
      }
    };
    init();
  }, [trial]);

  if (localLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="default-page-container">
      <div style={{ flex: 1, overflow: "auto", display: "flex", gap: "10px", flexDirection: "column", textAlign: 'left', boxSizing: 'border-box', paddingRight: '100px' }}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'left',
          }}
        >
          {trial?.training_title}
        </Typography>
        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          <SupportAgentIcon />
          <Typography variant="body2" style={{ display: "inline", margin: 0, paddingLeft: "8px" }}>{trial?.agent_name}</Typography>
        </div>
        <div style={{ marginBottom: '30px', display: "flex", alignItems: "center" }}>
          <EventAvailableIcon />
          <Typography variant="body2" style={{ display: "inline", margin: 0, paddingLeft: "8px" }}>{trial?.complete_date}</Typography>
        </div>
          
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginBottom="5px"
        >
          <Typography variant="h6">Overall Score</Typography>
          <Typography variant="h6" align="right">
            {feedback!.overall_score} / 5
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={Number(feedback!.overall_score) * 20}
            sx={{
              height: 4,
              backgroundColor: '#e0e0e0',
              marginBottom: "20px",
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'green',
              },
            }}
          />
        </Box>
        <Typography variant="body2" sx={{marginBottom: "30px"}}>{feedback!.summary}</Typography>
        <ProgressAccordion feedback={feedback}></ProgressAccordion>
      </div>

      <Box
        sx={{
          width: "2px",
          backgroundColor: "black",
        }}
      />
      <audio controls style={{flex: 1, transform: 'scaleY(0.85)', paddingLeft: '100px'}}>
        <source src={audioUrl} type="audio/wav" />
      </audio>
    </div>
    
  );
};
