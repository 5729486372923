import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import "./CallToolCard.css";
import { TrainingScenario } from "../../types/TrainingScenario";

interface CallToolCardProps {
  assignment: TrainingScenario;
}

const CallToolCard: React.FC<CallToolCardProps> = ({ assignment }) => {
  const [activeTab, setActiveTab] = useState<number | null>(1); // Default to Notes tab
  const [notes, setNotes] = useState("");
  const [savedNotes, setSavedNotes] = useState<{ text: string; date: string }[]>([]);
  const [isHintRevealed, setIsHintRevealed] = useState(false);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const handleAddNote = () => {
    if (notes.trim() !== "") {
      const newNote = {
        text: notes.trim(),
        date: new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      };
      setSavedNotes((prev) => [newNote, ...prev]);
      setNotes("");
    }
  };

  return (
    <Card className="call-tool-card">
      <CardHeader
        title={
          <div className="call-tool-tabs">
            {["Info Lookup", "Notes", "Agent Assist", "Hint", "Tools"].map(
              (tab, index) => (
                <Button
                  key={index}
                  className={`MuiButton-root call-tool-tab-button ${
                    activeTab === index ? "tab-active" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                  disabled={index === 0 || index === 2 || index === 4 || (index === 3 && !assignment.training.hint) } // Disable Info Lookup and Agent Assist
                >
                  {tab}
                </Button>
              )
            )}
          </div>
        }
        className="call-tool-header"
      />
      <CardContent className="call-tool-content">
        {activeTab === 1 && (
          <Box className="call-tool-outer-box">
              <TextField
                className="notes-area"
                multiline
                fullWidth
                minRows={3}
                maxRows={10}
                variant="outlined"
                placeholder={`Notes for ${assignment.training.training_title}`}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                InputProps={{
                  className: "notes-textfield",
                }}
              />
          
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} className="date-and-add-button">
              <Typography variant="caption" className="notes-date">
                {new Date().toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Typography>
              <Button
                variant="contained"
                size="small"
                className="add-note-button"
                onClick={handleAddNote}
              >
                Add Note
              </Button>
            </Box>
          
            <Box mt={2} display="flex" flexDirection="column" gap={1} className="notes-list-container">
              {savedNotes.map((note, idx) => (
                <Card key={idx} className="saved-note-card">
                  <Typography variant="body2" className="note-text">
                    {note.text}
                  </Typography>
                  <Typography variant="caption" className="notes-date">
                    {note.date}
                  </Typography>
                </Card>
              ))}
            </Box>

          </Box>        
        )}
        {activeTab === 3 && (
          <div className="hint-section">
            <Paper
              className={`MuiPaper-root single-hint-paper ${
                assignment.training.hint ? "clickable" : "disabled"
              }`}
              elevation={3}
              onClick={() => assignment.training.hint && setIsHintRevealed((prev) => !prev)} // Toggle on click
            >
              {assignment.training.hint ? (
                isHintRevealed ? (
                  <>
                    <Typography variant="body2" className="hint-text">
                      Hint: {assignment.training.hint}
                    </Typography>
                    <Typography variant="caption" className="hint-hide-text">
                      Click to hide hint
                    </Typography>
                  </>
                ) : (
                  <Typography variant="caption" className="hint-show-text">
                    Click to reveal hint
                  </Typography>
                )
              ) : (
                <Typography variant="body2" className="hint-text disabled">
                  No Hint Available
                </Typography>
              )}
            </Paper>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default CallToolCard;
