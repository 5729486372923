import axiosClient from './axiosClient';

interface UploadResponse {
    blobName: string;
}

export const hasSpeechEnded = async (sentence: string, conversationId: string): Promise<boolean> => {
    const response = await axiosClient.get<boolean>('/audio/hasSpeechEnded', {
        params: { sentence, conversationId }
    });
    return response.data;
}

export const getPlayback = async (blobName: string): Promise<Blob> => {
    const response = await axiosClient.get<Blob>('/audio/getPlayback', {
        params: { blobName },
        responseType: "blob"
    });
    return response.data;
}

export const audioUpload = async (formData: FormData): Promise<string> => {
    const response = await axiosClient.post<UploadResponse>('/audio/upload',
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
    );
    return response.data.blobName;
}